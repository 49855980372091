import httpManager from "../axios/httpManager";

export function getLeisureGameList  (search, pageNum, pageSize, orderBy) {
    let url = '/leisure/bet/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}

export function updateLeisureGame (leisureBet) {
    return httpManager.post('/leisure/bet/update', leisureBet);
}

export function updateLeisureGameResultCancel  (leisureBet) {
    return httpManager.post('/leisure/bet/result_cancel', leisureBet);
}

export function getLeisureGameBetStatisticByAttribute  (search) {
    return httpManager.post('/leisure/bet/get_statistic_by_attribute', search);
}
